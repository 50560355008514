import type { BootstrapResponse } from '@vue-storefront/prestashop-api';
import { useSdk } from '~/sdk';

interface UseBootsrap {
  data: BootstrapResponse['psdata'] | null;
  status: 'idle' | 'loading' | 'success' | 'error';
  error: string | null;
}

export function useBootstrap() {
  const state = useState<UseBootsrap>('bootstrap', () => ({
    data: null,
    status: 'idle',
    error: null,
  }));

  async function refresh() {
    console.log('bootstrapping...');
    state.value.status = 'loading';
    state.value.error = null;

    const { data, status, error } = await useAsyncData(`bootstrap`, () => useSdk().commerce.bootstrap());

    useHandleError(error.value);

    state.value.data = data.value as any;
    state.value.status = status.value;
    state.value.error = error.value?.message ?? (null as any);
  }

  return {
    ...toRefs(state.value),
    execute: refresh,
  };
}
